<template>
  <div class="activity">
    <music-note v-if="activity.bgmBtnSwitch" :mp3-path="activity.bgmPath" :position="activity.bgmBtnPosition" />
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="darkred">
      <van-swipe-item v-for="(item, idx) in coverList" :key="idx">
        <img :src="item" />
      </van-swipe-item>
    </van-swipe>
    <div class="activity_info">
      <div class="title">{{ activity.title }}{{ tempVersion }}</div>
      <div class="descn">{{ activity.descn }}</div>
      <div class="price">
        <span class="orig_price">{{ activity.price | formatMoney }}</span>
        <!-- <span class="line_price">{{ activity.linePrice | formatMoney }}</span> -->
      </div>
      <div class="item">
        <span class="label">活动时间：</span><span class="value">{{ activity.startDate }} 至 {{ activity.endDate }}</span>
      </div>
      <div ref="activityContent" class="content" />
    </div>

    <div v-if="referee.id" class="referee-wrapper">
      <div class="title">推荐人：</div>
      <div class="avatar-box"> <img :src="referee.avatar" /></div>
      <div class="nickname"> {{ referee.nickname }}</div>

    </div>
    <div class="buyer-wrapper">
      <span class="title">最新办理：</span>
      <span v-for="(item, idx) in buyerQueue" :key="idx" class="avatar-queue">
        <img :src="item" />
      </span>
    </div>

    <SharePoster v-if="activity.activityStatus ===0 && activity.shareBtnSwitch" :aid="activity.id" atype="share" :position="activity.shareBtnPosition" />

    <!-- 当前登录：{{ userInfo.nickname }}
    <img :src="userInfo.avatar" style="width: 50px; border-radius: 50%" />
    loc:{{ loc }}<br />
    initLink:{{ initLink }} -->

    <van-goods-action>
      <van-goods-action-button v-if="activity.activityStatus === 0" type="danger" text="立即购买" @click="buyNow" />
      <van-goods-action-button v-if="activity.activityStatus === -1" type="danger" text="活动未开始" />
      <van-goods-action-button v-if="activity.activityStatus === 1" type="danger" text="活动已结束" />
    </van-goods-action>
  </div>
</template>
<script>
import wxconfig from '@/wx'
import activityApi from '@/api/activity'
import wxUserApi from '@/api/wxuser'
import orderApi from '@/api/order'

import wxComPayApi from '@/api/wxcom-pay'

import MusicNote from '@/views/components/MusicNote.vue'
import SharePoster from '@/views/components/SharePoster.vue'

import { isOnline } from '@/utils/comm'

export default {
  components: {
    MusicNote,
    SharePoster
  },
  data() {
    return {
      appId: -2,
      refereeId: -2, // 推荐人ID
      activity: {
        id: '',
        title: '',
        descn: ''
      },
      coverList: [],
      collected: false,
      referee: {
        nickname: ''
      },
      loc: window.location.href,
      initLink: sessionStorage.getItem('initLink'),
      buyerQueue: [],
      tempVersion: ''
    }
  },

  computed: {
    userInfo() {
      return this.$store.getters.userInfo
    }
  },
  created() {
    this.appId = this.$route.params.appId
    this.activity.id = this.$route.params.id
    this.refereeId = this.$route.params.refereeId
    this.loadActivityData()
    this.loadBuyerQueue()
    this.tempVersion = this.$route.query.v
  },
  mounted() {},
  methods: {
    // 加载购买人
    loadBuyerQueue() {
      activityApi.loadBuyerQueue(this.activity.id).then(res => {
        if (res.succ) {
          this.buyerQueue = res.data.items
        }
      })
    },
    // 直接购买
    buyNow() {
      let data = {
        activityId: this.activity.id,
        p2: this.refereeId
      }
      // this.$toast(JSON.stringify(data))
      wxComPayApi.distributeBuy(data).then(res => {
        // this.$toast(JSON.stringify(res))
        if (res.succ) {
          let data = res.data
          if (data.existPayed) {
            // 已购买相应产品
            this.$dialog
              .alert({
                title: '提示',
                message: '您已参加此活动，点击确定查看详情！',
                theme: 'round-button'
              })
              .then(() => {
                this.$router.push(`/${this.appId}/ucenter/order/detail/${data.orderNum}`)
              })
          } else if (data.existNotPay) {
            // 有相同活动的未超时&未支付订单
            this.$dialog
              .alert({
                title: '提示',
                message: '您有未支付订单，点击确定查看详情！',
                theme: 'round-button'
              })
              .then(() => {
                this.$router.push(`/${this.appId}/ucenter/order/detail/${data.orderNum}`)
              })
          } else if (data.maxPerson) {
            this.$dialog.alert({
              title: '提示',
              message: '本次活动已达参与人数上限！',
              theme: 'round-button'
            })
          } else {
            this.$wx.chooseWXPay({
              appId: data.appId,
              timestamp: data.timeStamp,
              nonceStr: data.nonceStr,
              package: data.package,
              signType: data.signType,
              paySign: data.paySign,
              success: res => {
                // 支付成功后跳转，已经被微信“点金计划”阻止，不可自定义支付后跳转
                // this.$router.replace({ path: '/' })
              }
            })
          }
        }
      })
    },
    // 加载活动信息
    loadActivityData() {
      activityApi.get(this.activity.id).then(res => {
        if (res.succ) {
          let item = res.data.item
          if (!item) {
            this.$toast('活动不存在')
          } else {
            this.activity = item
            this.coverList.push(item.cover)
            this.$refs.activityContent.innerHTML = item.content
            // 初始化微信相关
            this.addViewer()
            this.initWxConfig()
          }
        }
      })
      this.showRefereeInfo()
    },
    addViewer() {
      // 添加活动访问记录
      let data = {
        activityId: this.activity.id,
        viewerId: this.userInfo.id,
        refereeId: this.fromId
      }
      activityApi.addViewer(data).then(res => {
        if (res.succ) {
          console.log('添加成功')
        }
      })
    },
    showRefereeInfo() {
      // 展示推荐人信息
      if (this.refereeId > 0) {
        // 推荐人信息
        wxUserApi.getUserInfoById(this.refereeId).then(res => {
          if (res.succ) {
            this.referee = res.data.item
          }
        })
      }
    },
    initWxConfig() {
      // 配置微信信息
      if (isOnline()) {
        wxconfig([this.initShareData])
      }
    },
    initShareData() {
      // 配置分享信息
      let userInfoId = this.userInfo.id
      let link = process.env.VUE_APP_BASE_URL + `/${this.appId}/distribute/${this.activity.id}/${userInfoId}?v=1.01`
      // 分享给好友
      this.$wx.updateAppMessageShareData({
        title: this.activity.title,
        desc: this.activity.descn,
        link: link,
        imgUrl: this.activity.cover,
        success(res) {
          console.log('分享参数设置成功', res)
        },
        complete() {
          console.log('share complete')
        },
        fail(error) {
          console.log('设置分享参数错误：', JSON.stringify(error))
        }
      })
      // 分享到朋友圈
      this.$wx.updateTimelineShareData({
        title: this.activity.title,
        desc: this.activity.descn,
        link: link,
        imgUrl: this.activity.cover,
        success(res) {
          console.log('分享参数设置成功', res)
        },
        complete() {
          console.log('share complete')
        },
        fail(error) {
          console.log('设置分享参数错误：', JSON.stringify(error))
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.activity {
  position: relative;
  background-color: #fff;
}

.my-swipe {
  height: 280px;
  img {
    width: 100%;
    height: 280px;
  }
}
.activity_info {
  padding: 4px 10px;
  position: relative;
  font-size: 0.36rem;
  .title {
    font-size: 22px;
    min-height: 28px;
    line-height: 28px;
    font-weight: normal;
  }
  .descn {
    margin: 6px 0;
    font-size: 14px;
    color: #888;
  }
  .price {
    margin: 6px 0;
    .orig_price {
      color: darkred;
      font-size: 20px;
      font-weight: bold;
    }
    .line_price {
      padding-left: 10px;
      color: #aaa;
      font-size: 12px;
      text-decoration: line-through;
    }
  }
  .item {
    height: 0.8rem;
    line-height: 0.8rem;
    .label {
      width: 1rem;
    }
    .value {
      color: #999;
      width: 1rem;
      text-align: left;
    }
  }
  .content {
    /deep/ p {
      img {
        display: block;
      }
    }
  }
}

.referee-wrapper {
  padding: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 1.4rem;
  line-height: 1.4rem;
  margin-bottom: 0.2rem;
  font-size: 0.36rem;
  .title {
    border-left: 0.2rem solid darkred;
    height: 0.5rem;
    line-height: 0.5rem;
    padding-left: 0.1rem;
    font-weight: bold;
  }
  .avatar-box {
    img {
      width: 1rem;
      height: 1rem;
      display: block;
      border: 1px solid #aaa;
      border-radius: 50%;
    }
  }
  .nickname {
    text-align: left;
    padding-left: 10px;
    color: #aaa;
  }
}
.buyer-wrapper {
  min-height: 1rem;
  line-height: 1rem;
  margin-bottom: 1rem;
  overflow: auto;
  padding-left: 0.56rem;
  padding-right: 0.2rem;
  font-size: 0.36rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  .title {
    margin-left: -14px;
    margin-right: 0.4rem;
    border-left: 0.2rem solid darkred;
    height: 0.5rem;
    line-height: 0.5rem;
    padding-left: 0.1rem;
    font-weight: bold;
  }
  .avatar-queue {
    margin: 0.2rem 0;
    img {
      width: 1rem;
      height: 1rem;
      display: block;
      border: 1px solid #aaa;
      border-radius: 50%;
      margin-left: -14px;
    }
  }
}
</style>
